









































































































import { EventTypes } from '@/constants/event-type-constants';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { StaffStore } from '@/staff/store/staff-store';
import { User } from '@/staff/models/user';
import { getAvatarBackgroundFromUser } from '@/core/avatar-utils';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { EnrollmentCenterSettingsStore } from '@/enrollment-center/store/enrollment-center-settings-store';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { Center } from '@/organizations/locations/models/center';
import { StaffUtils } from '@/staff/staff-utils';

const centerState = getModule(CentersStore);
const staffState = getModule(StaffStore);
const staffUtils = new StaffUtils();
const featuresState = getModule(FeaturesStore);
const enrollmentCenterSettingsState = getModule(EnrollmentCenterSettingsStore);

@Component({
    components: {}
})
export default class CenterAscendingStaffList extends Mixins(LocaleMixin, BasicValidationMixin) {
    // Whether this is a required field.
    @Prop({ type: Boolean, default: false }) readonly ariaRequired!: boolean;
    // Whether to display avatar
    @Prop({ type: Boolean, default: true }) readonly avatarDisplay!: boolean;
    // The given center.
    @Prop({}) readonly centerId!: number | null;
    // Clearable
    @Prop({ type: Boolean, default: false }) readonly clearable!: boolean;
    // Whether the select list should hide hints and errors.
    @Prop({ default: false }) readonly hideDetails!: boolean | string;
    // whether ET should be a possible option
    @Prop({ type: Boolean, default: false }) readonly includeET!: boolean;
    // Whether this input is disabled.
    @Prop({ default: false }) isDisabled!: boolean;
    // Whether the select list should be emphasized.
    @Prop({ type: Boolean, default: true }) readonly isEmphasized!: boolean;
    // The label.
    @Prop({ type: String, default: null }) readonly label!: string | null;
    // Don't set the default user to the current user if selected is null
    @Prop({ type: Boolean, default: false }) readonly noDefault!: boolean;
    // whether ET users should be the only visible users
    @Prop({ type: Boolean, default: false }) readonly onlyET!: boolean;
    // The default selected staff member.
    @Prop({ type: Number, default: null }) readonly selectedStaff!: number | null;
    // Model
    @Prop({ type: Number, default: null }) readonly value!: number | null;
    // Show all staff members that can access the center.
    @Prop({ type: Boolean, default: false }) readonly showAllAccessible!: boolean;

    private selected: number | null = null;
    private staffMembers: Array<User> = [];
    private center: Center | null = null;
    private etOption = false;

    get centerStaff() {
        return staffUtils.getCenterStaffList(this.staffMembers, this.onlyET, this.etOption, enrollmentCenterSettingsState.settings, this.centerId);
    }

    @Watch('selectedStaff')
    selectedStaffChange() {
        this.selected = this.selectedStaff;
    }

    @Watch('centerId')
    async centerUpdated() {
        if (this.centerId) {
            this.center = await centerState.getById(this.centerId);
            this.setSelectedValue();
            if (this.showAllAccessible) {
                // Get the organization id of the center.
                if (this.center) {
                    await staffState.initForOrg(this.center.organization_id, true);
                    this.staffMembers = staffState.storedOrgAccessibleStaff.get(this.center.organization_id) as Array<User>;
                }
            } else {
                await staffState.initForCenter(this.centerId, true);
                this.staffMembers = staffState.storedCenterStaff.get(this.centerId) as Array<User>;
            }
        } else {
            this.staffMembers = [];
        }

    }

    async created() {
        if (this.centerId) {
            await staffState.initForCenter(this.centerId, true);
            await centerState.init();
            this.center = await centerState.getById(this.centerId);
            this.staffMembers = staffState.storedCenterStaff.get(this.centerId) as Array<User>;
        }
        this.setSelectedValue();

        if (this.includeET) {
            await featuresState.init();
            if (featuresState.isFeatureEnabled(FeatureConstants.ENROLLMENT_CENTER)) {
                await enrollmentCenterSettingsState.init();
                this.etOption = true;
            }
        }

        if (this.onlyET) {
            await featuresState.init();
            if (featuresState.isFeatureEnabled(FeatureConstants.ENROLLMENT_CENTER)) {
                await staffState.initAllStaff(true);
                this.staffMembers = staffState.storedAllStaff as Array<User>;
            }
        }

        this.$emit(EventTypes.LOADED);
    }

    private avatarBackground(staffId: number) {
        return getAvatarBackgroundFromUser(staffId);
    }

    private setSelectedValue() {
        this.selected = this.selectedStaff ? this.selectedStaff : !this.noDefault ? staffUtils.getCenterDirectorId(this.center) : null;

        if (this.selected !== this.selectedStaff) {
            this.$emit('input', this.selected);
        }
    }

    formatAvatarInitials(staffId: number) {
        for (const staff of this.staffMembers) {
            if (staff.id === staffId) {
                return staff.first_name.substr(0, 1).toUpperCase() + staff.last_name.substr(0, 1).toUpperCase();
            }
        }

        return '';
    }

}
