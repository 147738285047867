










































































































































































import { ViewableLinkedFamilies, LinkCenter } from '@/families/models/linkReferTransfer';
import { Center } from '@/organizations/locations/models/center';
import { AnyStoreKeys, AnyStoreUtils } from '@/utils/any-store-utils';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { AuthStore } from '@/store/auth-store';
import { BaseStatuses } from '@/constants/status-constants';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { ChangeStatus } from '@/families/change-status';
import { Child } from '@/families/models/child';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { fixArchivedLeadStatuses, getViewableReferredFamilies } from '@/families/families-utils';
import { EventTypes } from '@/constants/event-type-constants';
import { Family, PendingFamily } from '@/families/models/family';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import { PermissionName } from '@/staff/models/user-permission-models';
import { SixAgentStore } from '@/integrations/store/six-agent-store';
import { SixAgentUtils } from '@/integrations/six-agent-utils';
import { StaffUtils } from '@/staff/staff-utils';
import { StatusesStore } from '@/families/store/statuses-store';
import FamilyStatusBar from '@/families/components/FamilyStatusBar.vue';
import FamilyData from '@/families/components/new/FamilyData.vue';
import FamilyHubActivity from '@/families/components/new/FamilyHubActivity.vue';
import ReferTransferFamily from '@/families/components/ReferTransferFamily.vue';
import SettingsFamilyHub from '@/families/components/SettingsFamilyHub.vue';
import store from '@/store';
import { checkDuplicatesForExistingFamily } from '@/families/search-families';
import { Org } from '@/models/organization/org';
import { AppStateStore } from '@/store/app-state-store';
import { LayoutTab, LayoutTabsStore } from '@/store/layout-tabs-store';
import BaseClose from '@/components/base/BaseClose.vue';
import { PageTitleMixin } from '@/core/page-title-mixin';
import BasePageTitle from '@/components/base/BasePageTitle.vue';
import DuplicatesReviewModal from '@/families/components/new/potential-duplicates/DuplicatesReviewModal.vue';

const anyStoreUtils = new AnyStoreUtils();
const authState = getModule(AuthStore, store);
const centersStore = getModule(CentersStore);
const changeStatusUtil = new ChangeStatus();
const familiesRepo = new FamiliesRepository();
const featuresState = getModule(FeaturesStore);
const loadingState = getModule(LoadingStore);
const sixAgentState = getModule(SixAgentStore);
const sixAgentUtil = new SixAgentUtils();
const staffUtils = new StaffUtils();
const statusesState = getModule(StatusesStore);
const appState = getModule(AppStateStore);
const layoutTabsStore = getModule(LayoutTabsStore, store);

@Component({
    components: {
        DuplicatesReviewModal,
        BasePageTitle,
        BaseClose,
        FamilyHubActivity,
        FamilyData,
        FamilyStatusBar,
        ReferTransferFamily
    }
})
export default class NewFamilyHub extends Mixins(LocaleMixin, PageTitleMixin) {
    // The id of the family
    @Prop({ type: Number, required: true }) readonly id!: number;

    private canEditFamily = false;
    private family: Family | null = null;
    private familyCenterId = 0;
    private loadingKey = 'familyHub';
    private closeEvent = EventTypes.CLOSE;
    private updatedEvent = EventTypes.UPDATED;
    private settingsFamilyPermissionGrant = false;
    private childAddedEvent = EventTypes.CHILD_ADDED;
    private childDeletedEvent = EventTypes.CHILD_DELETED;
    private childEditedEvent = EventTypes.CHILD_EDITED;
    private familySettings = SettingsFamilyHub;
    private isShowingOtherLocationFamily = false;
    private showOtherLocationFamilySnackbar = false;
    private showReferTransferModal = false;
    private showSnackbar = false;
    private snackbarMessage: string | null = null;
    private activityKey = 1;
    private matchingFamilies: Array<number> = [];
    private viewPotentialDuplicatesModal = false;
    private showPotentialDuplicateModal = false;
    private duplicatesColumnSize = 6;
    private duplicatesDialogSize = 'dialog-medium';
    private tabIndex = 0;
    private potentialDuplicates: Array<Family | PendingFamily> = [];
    private isLoaded = false;

    /**
     * The centers that can be referred to.
     */
    get accessibleCenters(): Array<Center> {
        return centersStore.storedAccessibleCenters;
    }

    private get hasCrmPlusMode() {
        return featuresState.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    private get isLineLeaderEnroll(): boolean {
        return featuresState.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL);
    }

    private get isSuperUser() {
        return authState.isSuperuser;
    }

    private get isReferDisabled() {
        return this.referrableCenters.length <= 1;
    }

    private get org(): Org | null {
        return appState.storedCurrentOrg;
    }

    /**
     * Get the message for the snackbar when viewing a family at another location from the other locations of interest table.
     */
    private get otherLocationFamilyMessage(): string {
        const familyName = this.generateFamilyHubTitle();
        const centerCode = this.family?.center ? (this.family.center.values.code ?? this.family.center.values.name) : 'their other location';
        return `Now viewing the ${familyName} family at ${centerCode}`;
    }

    /**
     * The centers that can be referred to.
     */
    get referrableCenters(): Array<Center> {
        return centersStore.storedReferrableCenters.filter(center => center.is_active);
    }

    /**
     * Get the center links of families that the given family has been referred to
     * and filtered to only include the centers that the current user can refer to.
     */
    private get viewableReferredCenters(): Array<ViewableLinkedFamilies> {
        if (!this.family || !this.family.linked_families || !this.referrableCenters.length) {
            return [];
        }
        const viewableLinkedFamilies = getViewableReferredFamilies(this.family, this.referrableCenters)
            .map(linkedFamily => {
                return {
                    familyId: linkedFamily.family.id,
                    centerId: (linkedFamily.center as LinkCenter).id,
                    centerName: (linkedFamily.center as LinkCenter).values.name,
                    disabled: false
                } as ViewableLinkedFamilies;
            });
        // Add the current family info to the list
        viewableLinkedFamilies.push({
            familyId: this.family.id,
            centerId: this.familyCenterId,
            centerName: this.family.center?.values.name ?? '',
            disabled: true
        });
        return viewableLinkedFamilies;
    }

    @Watch('org', { deep: true })
    private async orgLoaded() {
        await this.loadFamilyHub();
    }

    @Watch('id', { immediate: true })
    private async loadFamilyHub() {
        if (!this.org) {
            return;
        }

        // Make sure the id is valid.
        if (this.id === 0) {
            await this.$router.push({ name: 'families' });
        }
        this.isLoaded = false;
        loadingState.loadingIncrement(this.loadingKey);

        // Pull family data.
        try {
            this.family = await familiesRepo.getOne(this.id, { log_view: true }, true);
        } catch (ex) {
            await this.$router.push({ name: 'families' });
            return;
        }

        // Make sure we have statuses loaded.
        if (!statusesState.statuses.length) {
            await statusesState.init();
        }

        // Make sure the family has a valid status.
        if (this.family?.status === null) {
            // Invalid status, try to fix.
            await changeStatusUtil.fixStatus(this.family.id);
            this.family = await familiesRepo.getOne(this.id);
        }

        // Check the children for invalid statuses.
        if (this.family?.children?.length) {
            for (const child of this.family.children) {
                if (child.status === null) {
                    await changeStatusUtil.fixStatus(this.family.id);
                    this.family = await familiesRepo.getOne(this.id);
                    break;
                }
            }
        }

        if (this.isLineLeaderEnroll) {
            this.setPageTitle('Family Hub');
        } else {
            this.setPageTitle(this.generateFamilyHubTitle() + ' Family Hub');
        }

        this.familyCenterId = this.family.center?.id ?? 0;
        this.setCanEditFamily();

        if (anyStoreUtils.storedValue(AnyStoreKeys.SHOW_OTHER_FAMILY_SNACKBAR)) {
            this.showOtherLocationFamilySnackbar = true;
        }

        anyStoreUtils.setStoreValue({ key: AnyStoreKeys.SHOW_OTHER_FAMILY_SNACKBAR, val: false });
        if (this.isShowingOtherLocationFamily) {
            this.showOtherLocationFamilySnackbar = true;
            this.isShowingOtherLocationFamily = false;
        }

        await this.potentialDuplicatesLookup();

        if (sixAgentState.loggedIn) {
            sixAgentUtil.addPossibleContact(this.family);
        }

        if (this.checkForArchivedLead(this.family)) {
            await fixArchivedLeadStatuses(this.family, changeStatusUtil);
            // re-fetch family now that it's been updated
            this.family = await familiesRepo.getOne(this.id);
        }

        this.updateTab();
        this.isLoaded = true;
        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * Set whether the family can be edited by the current user.
     */
    @Watch('accessibleCenters', { immediate: true, deep: true })
    private setCanEditFamily() {
        if (!this.familyCenterId || !this.accessibleCenters.length) {
            return;
        }
        this.canEditFamily = this.accessibleCenters.find(center => center.id === this.familyCenterId) !== undefined;
        anyStoreUtils.setStoreValue({ key: AnyStoreKeys.CAN_EDIT_FAMILY, val: this.canEditFamily });
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);

        const featuresPromise = featuresState.init();
        const centersPromise = centersStore.initReferrableCenters();
        const accessiblePromise = centersStore.initAccessibleCenters();
        await featuresPromise;
        await centersPromise;
        await accessiblePromise;

        this.tabIndex = layoutTabsStore.currentTabIndex;

        loadingState.loadingDecrement(this.loadingKey);
    }

    async mounted() {
        this.settingsFamilyPermissionGrant = await staffUtils.getUserPermission(PermissionName.SettingsAllGears);
        this.setCanEditFamily();
    }

    async reloadFamily() {
        if (!this.id) {
            return;
        }

        loadingState.loadingIncrement(this.loadingKey);

        this.family = await familiesRepo.getOne(this.id);
        await this.potentialDuplicatesLookup();
        this.setCanEditFamily();
        this.setPageTitle(this.generateFamilyHubTitle() + ' Family Hub');
        this.activityKey++;

        if (this.checkForArchivedLead(this.family)) {
            await fixArchivedLeadStatuses(this.family, changeStatusUtil);

            // Re-fetch family now that it's been updated.
            this.family = await familiesRepo.getOne(this.id);
        }

        this.updateTab();

        loadingState.loadingDecrement(this.loadingKey);
    }

    private generateFamilyHubTitle() {
        if (this.family) {
            const primaryName = this.family.primary_guardian.last_name;
            // only primary guardian
            if (this.family.guardians.length === 1) {
                return primaryName;
            }
            // multiple guardians
            if (this.family.guardians.length > 1) {
                const secondaryName = this.family.guardians[1].last_name;
                // last names match
                if (secondaryName === primaryName) {
                    return primaryName;
                }
                // last names don't match
                if (secondaryName !== primaryName) {
                    // primary has a hyphenated name that includes the last name of secondary
                    if (primaryName.includes('-') && primaryName.toLowerCase().includes(secondaryName.toLowerCase())) {
                        return primaryName;
                        // does not include the last name of secondary
                    } else if (primaryName.includes('-') && !primaryName.toLowerCase().includes(secondaryName.toLowerCase())) {
                        return `${primaryName} / ${secondaryName}`;
                    }
                    // secondary has a hyphenated name that includes the last name of the primary
                    if (secondaryName.includes('-') && secondaryName.toLowerCase().includes(primaryName.toLowerCase())) {
                        return secondaryName;
                        // does not include the last name of primary
                    } else if (secondaryName.includes('-') && !secondaryName.toLowerCase().includes(primaryName.toLowerCase())) {
                        return `${primaryName} / ${secondaryName}`;
                    }
                    return `${primaryName} / ${secondaryName}`;
                }
            }
        }

        return '';
    }

    private checkForArchivedLead(family: Family): boolean {
        // check to see if Lead is in Lost Opp status but has active children.
        if (family.children.length && family.status?.id === BaseStatuses.LOST_OPP) {
            const activeStatuses = statusesState.activeStatusIds;
            for (const child of family.children) {
                if (child.status && activeStatuses.includes(child.status.id)) {
                    return true;
                }
            }
        }
        return false;
    }

    private childDeleted(childId: number) {
        if (this.family) {
            this.family.children.splice(
                this.family.children.map(
                    (child: Child) => {
                        return child.id;
                    })
                    .indexOf(childId),
                1);
            this.snackbarMessage = 'Child deleted.';
            this.showSnackbar = true;
        }
    }

    private async childAdded() {
        await this.reloadFamily();
        this.snackbarMessage = 'Child Added.';
        this.showSnackbar = true;
    }

    private async childEdited() {
        await this.reloadFamily();
        this.snackbarMessage = 'Child Edited.';
        this.showSnackbar = true;
    }

    private updateTab() {
        const tab = layoutTabsStore.tabs[this.tabIndex] as LayoutTab;
        tab.props.routeName = this.$route.name ?? 'undefined';
        tab.props.routeParams = this.$route.params;
        tab.props.tabTitle = this.family?.primary_guardian.last_name ?? 'Family';
        layoutTabsStore.updateTab(tab);
    }

    private closeSnackbar() {
        this.showSnackbar = false;
        this.snackbarMessage = null;
    }

    /**
     * View a different family that is linked to this one.
     *
     * @param viewableLinkedFamily
     * @param showSnackbar
     */
    private viewOtherFamily(viewableLinkedFamily: ViewableLinkedFamilies, showSnackbar = false) {
        if (showSnackbar) {
            this.isShowingOtherLocationFamily = true;
        }
        this.$router.push({ name: 'family-hub', params: { id: viewableLinkedFamily.familyId.toString() } });
    }

    private async potentialDuplicatesLookup() {
        if (this.family && this.org) {
            this.potentialDuplicates = await checkDuplicatesForExistingFamily(this.family, this.org.id);
        }
    }

}
