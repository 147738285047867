
































































































































































































































































































































































































































































































































































































































import { Component, Watch, Prop, Mixins } from 'vue-property-decorator';

import CrmTypeSelectList from '@/crm-types/components/CrmTypeSelectList.vue';
import StatusChangeSelect from '@/families/components/StatusChangeSelect.vue';
import InlineEditable from '@/components/base/InlineEditable.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import { LoadingStore } from '@/store/loading-store';
import PotentialDuplicateService, {
    FamilyEntry,
    ChildEntry, PotentialDuplicateActionOption, PotentialDuplicateActionsSet
} from '@/families/services/potential-duplicate-service';
import {
    AcceptFamilyEventPayload,
    Family,
    PendingFamily,
    PotentialDuplicateActionConstants
} from '@/families/models/family';
import { LocaleMixin } from '@/locales/locale-mixin';
import DuplicatesDifferenceIndicator from '@/families/components/new/potential-duplicates/DuplicatesDifferenceIndicator.vue';
import { CrmTypeOption } from '@/crm-types/models/crm-type';
import DuplicateStatusChangeSelect
    from '@/families/components/new/potential-duplicates/DuplicateStatusChangeSelect.vue';
import {
    filterNonHideActions,
    getActionOptionsForFamily,
    getDialogSize, mergeChildInfo,
    mergeGuardianInfo
} from '@/families/potential-duplicate-utils';
import cloneDeep from 'lodash/cloneDeep';
import { StatusChangeInterface } from '@/families/models/status';

const appState = getModule(AppStateStore);
const loadingState = getModule(LoadingStore);
const potentialDuplicateService = new PotentialDuplicateService();

@Component({
  components: { DuplicateStatusChangeSelect, InlineEditable, StatusChangeSelect, CrmTypeSelectList, DuplicatesDifferenceIndicator }
})

export default class DuplicatesReviewModal extends Mixins(LocaleMixin) {
    @Prop({ default: false }) readonly value!: boolean;
    @Prop({ type: Array, default: null, required: true }) readonly duplicates!: Array<Family | PendingFamily>;
    @Prop({ type: Boolean, default: false }) readonly isPending!: boolean;

    private loadingKey = 'duplicatesReviewModal';
    private isLoaded = false;
    private localDialogSize = 'potential-duplicate-dialog-medium';
    private originalFamilyEntries: Array<FamilyEntry> = [];
    private currentFamilyEntries: Array<FamilyEntry> = [];
    private originalChildrenGroupedByName: Record<string, ChildEntry[]> = {};
    private currentChildrenGroupedByName: Record<string, ChildEntry[]> = {};
    private familyDifferencesRecord: Record<string, boolean> = {};
    private childrenDifferencesArray: Array<{ name: string; differences: Record<string, boolean> }> = [];
    private availableInquiryTypes: Array<CrmTypeOption> = [];
    private availableSourceTypes: Array<CrmTypeOption> = [];
    private availableStatusIdsForChildlessFamilies: Array<number> = [];
    private centerNames: Array<string> = [];
    private currentActionOptions: Record<number, PotentialDuplicateActionOption | null> = {};
    private previousActionOptions: Record<number, PotentialDuplicateActionOption | null> = {};
    private actionRecords: Record<number, PotentialDuplicateActionsSet> = {};
    private mergeTracking: Map<number, Array<number>> = new Map();
    private updatedEvent = EventTypes.UPDATED;

    get modelValue(): boolean {
        return this.value;
    }

    set modelValue(showIt: boolean) {
        this.$emit('input', showIt);
    }

    get familyEntries(): Array<FamilyEntry> {
        return this.currentFamilyEntries;
    }

    set familyEntries(value: Array<FamilyEntry>) {
        this.currentFamilyEntries = value;
        potentialDuplicateService.updateFamilyEntries(value);
    }

    get childrenGroupedByName(): Record<string, ChildEntry[]> {
        return this.currentChildrenGroupedByName;
    }

    set childrenGroupedByName(value: Record<string, ChildEntry[]>) {
        this.currentChildrenGroupedByName = value;
        potentialDuplicateService.updateChildrenGroupedByName(value);
    }

    get isSaveDisabled() {
        // Filter out records with action type `HIDE`
        const validActions = Object.values(this.currentActionOptions).filter((action) => {
            if (!action) return true; // Skip null values
            const actionType = action.value.split('-')[0];
            return actionType !== PotentialDuplicateActionConstants.HIDE;
        });
        // If any valid action is null, saving should be disabled
        return validActions.some((action) => action === null);
    }

    get isMini() {
        return appState.isMini;
    }

    @Watch('modelValue')
    private async loadFamiliesData() {
        if (this.modelValue) {
            loadingState.loadingIncrement(this.loadingKey);
            this.isLoaded = false;
            await this.initializeData();
            await this.reloadData();
            // Track the actions of save, merge, link, hide, and reject in this component, too much hassle to track in service class
            this.updateMergeTracking();
            this.initializeActions();
            this.isLoaded = true;
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    @Watch('familyEntries', { deep: true })
    private onFamiliesDtoChange(newValue: Array<FamilyEntry>) {
        potentialDuplicateService.updateFamilyEntries(newValue);
    }

    @Watch('childrenGroupedByName', { deep: true })
    private onChildrenGroupedByNameChange(newValue: Record<string, ChildEntry[]>) {
        potentialDuplicateService.updateChildrenGroupedByName(newValue);
    }

    private updateStatusSelect(index: number, status: StatusChangeInterface | null) {
        this.familyEntries[index].statusUpdates = status;
    }

    private updateChildStatusSelect(name: string, index: number, status: StatusChangeInterface | null) {
        this.childrenGroupedByName[name][index].statusUpdates = status;
    }

    private async initializeData() {
        await potentialDuplicateService.init(this.duplicates);
        this.currentFamilyEntries = potentialDuplicateService.familyEntries;
        this.originalFamilyEntries = cloneDeep(this.currentFamilyEntries);

        this.currentChildrenGroupedByName = potentialDuplicateService.childrenGrouped;
        this.originalChildrenGroupedByName = cloneDeep(this.currentChildrenGroupedByName);

        this.centerNames = potentialDuplicateService.centerRows;
        this.familyDifferencesRecord = potentialDuplicateService.familyDifferences;
        this.childrenDifferencesArray = potentialDuplicateService.childrenDifferences;
    }

    private async reloadData() {
        await potentialDuplicateService.setupSelectListOptions(this.familyEntries);
        this.localDialogSize = getDialogSize(this.familyEntries.length);

        this.availableInquiryTypes = potentialDuplicateService.inquiryTypesOptions;
        this.availableSourceTypes = potentialDuplicateService.sourceTypesOptions;
        this.availableStatusIdsForChildlessFamilies = potentialDuplicateService.statusesForChildlessFamilies;
    }

    private initializeActions() {
        this.actionRecords = {};
        this.currentActionOptions = {};
        for (const familyEntry of this.familyEntries) {
            this.actionRecords[familyEntry.familyDto.id] = {
                save: [
                    {
                        text: 'Save This Record',
                        value: `${PotentialDuplicateActionConstants.SAVE}-${familyEntry.familyDto.id}`
                    }
                ],
                merge: [],
                link: [],
                hide: [
                    {
                        text: 'Skip/Hide This Record',
                        value: `${PotentialDuplicateActionConstants.HIDE}-${familyEntry.familyDto.id}`
                    }
                ],
                reject: [
                    {
                        text: 'Reject This Record',
                        value: `${PotentialDuplicateActionConstants.REJECT}-${familyEntry.familyDto.id}`
                    }
                ]
            };
            this.updateSelectedActionOption(familyEntry.familyDto.id, null);
            this.previousActionOptions[familyEntry.familyDto.id] = null;
        }
    }

    private updateMergeTracking() {
        this.mergeTracking.clear();
        this.familyEntries.forEach((familyEntry) => {
            this.mergeTracking.set(familyEntry.family.id, []);
        });
    }

    private closeDialog() {
        this.modelValue = false;
        this.$emit(EventTypes.CLOSE);
    }

    private retrieveActionOptions(familyId: number): Array<PotentialDuplicateActionOption> {
        return getActionOptionsForFamily(familyId, this.familyEntries, this.currentActionOptions);
    }

    private async onActionChange(familyId: number, action: PotentialDuplicateActionOption) {
        const actionType = action.value.split('-')[0];

        const previousAction = this.previousActionOptions[familyId];
        const previousActionType = previousAction ? previousAction.value.split('-')[0] : null;
        if (actionType === PotentialDuplicateActionConstants.MERGE) {
            this.handleMergeAction(familyId, action);
        } else {
            if (previousAction && previousActionType && previousActionType === PotentialDuplicateActionConstants.MERGE) {
                this.handleUnmergeAction(familyId, previousAction);
            }
        }

        if (actionType === PotentialDuplicateActionConstants.HIDE) {
            await this.handleHideAction(familyId, action);
            return; // Exit early after handling the hide action
        }

        if (actionType !== PotentialDuplicateActionConstants.SAVE) {
            this.resetReferencesToFamily(familyId);
        }

        this.updateActionOptions(familyId, action);
        await this.reloadData();
    }

    private handleMergeAction(familyId: number, action: PotentialDuplicateActionOption) {
        const targetFamilyId = parseInt(action.value.split('-')[1]);
        if (!this.mergeTracking.has(targetFamilyId)) {
            this.mergeTracking.set(targetFamilyId, []);
        }
        // Avoid duplicate merges
        const currentMergingList = this.mergeTracking.get(targetFamilyId) || [];
        if (currentMergingList && !currentMergingList.includes(familyId)) {
            // If the current family id is less than the target family or the list is empty, add it to the list and do 1 single merge
            if (currentMergingList[currentMergingList.length - 1] <= familyId || currentMergingList.length === 0) {
                currentMergingList.push(familyId);
                this.doMerge(targetFamilyId, familyId);
            } else {
                // If the current family id is greater than the target family, add it to the list and sort the list and re-merge based on the order
                currentMergingList.push(familyId);
                currentMergingList.sort((a, b) => a - b);
                this.convertBackToOriginalState(targetFamilyId);
                for (const sourceFamilyId of currentMergingList) {
                   this.doMerge(targetFamilyId, sourceFamilyId);
                }
           }
           this.mergeTracking.set(targetFamilyId, currentMergingList);
        }
    }

    private handleUnmergeAction(familyId: number, previousAction: PotentialDuplicateActionOption) {
        const targetFamilyId = parseInt(previousAction.value.split('-')[1]);
        if (!this.mergeTracking.has(targetFamilyId)) return;

        // Remove the source family from the target's merge list
        const currentMergeList = this.mergeTracking.get(targetFamilyId) || [];
        const updatedMergesList = currentMergeList.filter((id) => id !== familyId);

        if (updatedMergesList.length > 0) {
            this.convertBackToOriginalState(targetFamilyId);
            for (const sourceFamilyId of updatedMergesList) {
               this.doMerge(targetFamilyId, sourceFamilyId);
            }
            this.mergeTracking.set(targetFamilyId, updatedMergesList);
        } else {
            this.convertBackToOriginalState(targetFamilyId);
            this.mergeTracking.delete(targetFamilyId); // Clean up empty entries
        }
    }

    // Handle "Skip/Hide This Record" action
    private async handleHideAction(familyId: number, action: PotentialDuplicateActionOption) {
        const result = await this.$swal({
            text: 'Are you sure you want to remove this column from the screen? Any edits you may have made will not be saved.',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Hide',
            cancelButtonText: 'Cancel',
            icon: 'warning'
        });

        if (result.isConfirmed) {
            this.hideFamilyFromDuplicates(familyId);
            await this.reloadData();
            this.updateActionOptions(familyId, action);
        } else {
            await this.reloadData;
            this.revertAction(familyId);
        }
    }

    // Reset references to the specified family when moving away from "Save"
    private resetReferencesToFamily(familyId: number): void {
        this.familyEntries.forEach((familyEntry) => {
            if (this.currentActionOptions[familyEntry.familyDto.id]?.value.includes(`-${familyId}`) && familyEntry.familyDto.id !== familyId) {
                this.updateSelectedActionOption(familyEntry.familyDto.id, null); // Reset to default empty state
            }
        });
    }

    // Update the selected and current action options
    private updateActionOptions(familyId: number, action: PotentialDuplicateActionOption): void {
        this.updateSelectedActionOption(familyId, action);
        this.previousActionOptions[familyId] = action;
    }

    // Revert the action for a family to the previous state
    private revertAction(familyId: number): void {
        const previousAction = this.previousActionOptions[familyId] || null;
        this.updateSelectedActionOption(familyId, previousAction);
    }

    // Remove a family from the duplicates list and clear references to it
    private hideFamilyFromDuplicates(familyId: number): void {
        const deletedIndex = this.familyEntries.findIndex((family) => family.familyDto.id === familyId);
        if (deletedIndex !== -1) {
            this.familyEntries.splice(deletedIndex, 1);
            this.centerNames.splice(deletedIndex, 1);
            Object.keys(this.childrenGroupedByName).forEach((key) => {
                this.childrenGroupedByName[key].splice(deletedIndex, 1);
            });
        }

        this.familyEntries.forEach((familyEntry) => {
            if (this.currentActionOptions[familyEntry.familyDto.id]?.value.includes(`-${familyId}`)) {
                this.updateSelectedActionOption(familyEntry.familyDto.id, null); // Reset to default empty state
            }
        });
    }

    private updateSelectedActionOption(familyId: number, action: PotentialDuplicateActionOption | null): void {
        this.$set(this.currentActionOptions, familyId, action);
    }

    private doMerge(targetFamilyId: number, sourceFamilyId: number) {
        const targetIndex = this.familyEntries.map(family => family.familyDto.id).indexOf(targetFamilyId);
        const sourceIndex = this.familyEntries.map(family => family.familyDto.id).indexOf(sourceFamilyId);
        if (targetIndex === -1 || sourceIndex === -1) return;

        const targetFamilyEntry = this.familyEntries[targetIndex];
        const sourceFamilyEntry = this.familyEntries[sourceIndex];

        mergeGuardianInfo(targetFamilyEntry, sourceFamilyEntry, this.familyEntries);
        mergeChildInfo(targetFamilyEntry, sourceFamilyEntry, this.childrenGroupedByName);
    }

    private convertBackToOriginalState(targetFamilyId: number) {
        const targetIndex = this.familyEntries.map(family => family.familyDto.id).indexOf(targetFamilyId);
        const ogEntry = this.originalFamilyEntries.find((f) => f.family.id === targetFamilyId);
        if (targetIndex !== -1 && ogEntry) {
            this.familyEntries[targetIndex] = cloneDeep(ogEntry);
            Object.entries(this.childrenGroupedByName).forEach(([name, children]) => {
                const ogChildrenEntries = this.originalChildrenGroupedByName[name];
                if (ogChildrenEntries) {
                    const targetChildIndex = children.findIndex(child => child.family.id === targetFamilyId);

                    if (targetChildIndex !== -1) {
                        const ogChildEntry = ogChildrenEntries.find(child => child.family.id === targetFamilyId);

                        if (ogChildEntry) {
                            // Update the specific child entry for the target family
                            this.childrenGroupedByName[name][targetChildIndex] = cloneDeep(ogChildEntry);
                        }
                    }
                }
            });
        }
    }

    private async save() {
        loadingState.loadingIncrement(this.loadingKey);
        await potentialDuplicateService.save(filterNonHideActions(this.currentActionOptions), this.isPending);
        if (!this.isPending) {
            this.$emit(EventTypes.UPDATED);
        } else {
            const familyBeingViewId = this.familyEntries[0].familyDto.id;
            const familyBeingViewAction = this.currentActionOptions[familyBeingViewId];
            if (familyBeingViewAction) {
                const familyBeingViewActionType = familyBeingViewAction.value.split('-')[0];
                if (familyBeingViewActionType !== PotentialDuplicateActionConstants.REJECT) {
                    this.$emit(EventTypes.FAMILY_ACCEPTED, { acceptedInDuplicateModal: true } as AcceptFamilyEventPayload);
                } else {
                    this.$emit(EventTypes.FAMILY_REJECTED, familyBeingViewId, null, true);
                }
            }
        }
        loadingState.loadingDecrement(this.loadingKey);
        this.closeDialog();
    }

}
