import {
    BasicFamilySearchParameters,
    BasicFamilySearchResult,
    Family,
    FamilyCreateDto, FamilyCreateDtoInterface,
    FamilySearchParameters
} from '@/families/models/family';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import { filterDuplicates } from '@/families/potential-duplicate-utils';
// Take a search string and break it up into tokens for the search query
export function tokenize(searchString: string): Array<string> {
    const tokens = searchString.split(' ');
    for (const token of tokens) {
        // Hyphenated names should be searched as individual tokens, as well as compound
        if (token.includes('-')) {
            for (const subToken of token.split('-')) {
                tokens.push(subToken);
            }
        }
    }
    return tokens;
}

// Search families based on a general search string
export async function basicSearch(params: BasicFamilySearchParameters): Promise<Array<BasicFamilySearchResult>> {
    const repo = new FamiliesRepository();
    // Await the results and return the results' property of the results.
    return await repo.basicSearch(params);
}

export async function search(params: FamilySearchParameters): Promise<Array<Family>> {
    const repo = new FamiliesRepository();
    // Await the results and return the results' property of the results.
    const results = await repo.search(params);
    return results.results;
}

export async function searchByCenterId(searchString: string, centerId: number | undefined): Promise<Array<Family>> {
    const params: FamilySearchParameters = { queries: tokenize(searchString) };
    if (centerId !== undefined) {
        params.center_ids = centerId.toString();
    }
    return search(params);
}

export async function basicSearchByOrgId(searchString: string, orgId: number, skipChildren = false, skipContacts = false): Promise<Array<BasicFamilySearchResult>> {
    const params: BasicFamilySearchParameters = { queries: tokenize(searchString), org_id: orgId, skip_children: skipChildren, skip_contacts: skipContacts };
    return basicSearch(params);
}

export async function searchByOrgId(searchString: string, orgId: number): Promise<Array<Family>> {
    const params: FamilySearchParameters = { queries: tokenize(searchString), org_id: orgId };
    return search(params);
}

export async function checkDuplicates(family: FamilyCreateDto | FamilyCreateDtoInterface | Family, orgId: number | null = null): Promise<Array<Family>> {
    const repo = new FamiliesRepository();
    const params: FamilySearchParameters = {
        guardian_first_name: family.primary_guardian.first_name,
        guardian_last_name: family.primary_guardian.last_name,
        email_address: family.primary_guardian.email,
        duplicate_check: true
    };

    if (family.primary_guardian.primary_phone) {
        params.phone_number = family.primary_guardian.primary_phone.number_e164;
    }

    if (orgId) {
        params.org_id = orgId;
    }

    const results = await repo.search(params);

    return results.results;
}

export async function checkDuplicatesForExistingFamily(currentFamily: Family, orgId: number): Promise<Array<Family>> {
    const searchResults = await checkDuplicates(currentFamily, orgId);
    return filterDuplicates(currentFamily, searchResults);
}
