






























































































































import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { AnonInfoService } from '@/customers/anon-info-service';

const loadingState = getModule(LoadingStore);
const infoService = new AnonInfoService();
@Component
export default class PrivacyPolicy extends Vue {
    private loadingKey = 'privacyPolicy';
    private legalName = '';

    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        const info = await infoService.getAnonInfo(String(this.$route.query.formId), String(this.$route.query.cid));
        this.legalName = info.legal_name || 'your Service Provider';
        loadingState.loadingDecrement(this.loadingKey);
    }

}
