
























































































































































































































































































import { PendingFamilyRecording, Recording, RecordingActions } from '@/communications/recordings/models/recording-models';
import { getPendingFamilyActions } from '@/families/families-utils';
import {
    AcceptFamilyEventPayload,
    Family,
    FamilyLink,
    PendingFamily,
    PendingFamilyActions
} from '@/families/models/family';
import { FeaturesStore } from '@/features/features-store';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import CenterlessFamilySelection from '@/families/components/CenterlessFamilySelection.vue';
import {
    ActionItem,
    ActionItemGroupType,
    ActionItemType,
    ToDoCounts
} from '@/dashboards/models/action-items-models';
import { DataTableOptions } from '@/models/datatables';
import { DataTableHeader } from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { ActionItemUtils } from '@/dashboards/action-item-utils';
import { AvatarValues } from '@/core/avatar-utils';
import { CrmBreakpointsMixin } from '@/styles/crm-breakpoints-mixin';
import { AppStateStore } from '@/store/app-state-store';
import { LoadingStore } from '@/store/loading-store';
import { EventTypes } from '@/constants/event-type-constants';
import LocationDashboard from '@/dashboards/views/LocationDashboard.vue';
import { FeatureConstants } from '@/features/feature-constants';
import { PendingFamilyService } from '@/families/pending-family-service';
import RejectFamily from '@/families/components/RejectFamily.vue';
import { CrmTypeList, CrmTypeOption, RejectedReasonIdentifiers } from '@/crm-types/models/crm-type';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import PotentialDuplicate from '@/families/components/PotentialDuplicate.vue';
import PendingFamilyHubModal from '@/families/views/PendingFamilyHubModal.vue';
import RecordingAudioModal from '@/communications/recordings/components/RecordingAudioModal.vue';
import AddFamily from '@/families/components/AddFamily.vue';
import { AddMeetingEventPayload, EditMeetingEventPayload } from '@/tasks/models/tour-calendar-models';
import TourCalendarModal from '@/tasks/components/TourCalendarModal.vue';
import SendMessageModal from '@/communications/messages/components/SendMessageModal.vue';
import ManageTaskModal from '@/tasks/components/ManageTaskModal.vue';
import { Task, TaskGroups } from '@/tasks/models/task-models';
import { TasksRepository } from '@/tasks/repositories/tasks-repository';
import AddTaskModal from '@/tasks/components/AddTaskModal.vue';
import { RecordingsRepository } from '@/communications/recordings/repositories/recordings-repository';
import { RecordingsStore } from '@/communications/recordings/stores/recordings-store';
import ViewMessageModal from '@/dashboards/components/LocationDashboardTabs/ViewMessageModal.vue';
import {
    BlockedPhoneNumbersRepository
} from '@/communications/blocked-phone-numbers/repositories/blocked-phone-numbers-repository';
import { TextsStore } from '@/communications/messages/stores/texts-store';
import { PendingLeadsRepository } from '@/families/repositories/lead/pending-leads-repository';
import BaseClose from '@/components/base/BaseClose.vue';
import { LayoutTabsStore } from '@/store/layout-tabs-store';
import { PstToursAvailabilityTimeRange } from '@/pst-tours/models/pst-tours-availability';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import DuplicatesReviewModal from '@/families/components/new/potential-duplicates/DuplicatesReviewModal.vue';

const authState = getModule(AuthStore, store);
const actionItemUtils = new ActionItemUtils();
const appState = getModule(AppStateStore);
const loadingState = getModule(LoadingStore);
const featuresState = getModule(FeaturesStore);
const pendingFamilyService = new PendingFamilyService();
const pendingLeadsRepository = new PendingLeadsRepository();
const typeStore = getModule(CrmTypesStore);
const tasksRepo = new TasksRepository();
const recordingsRepo = new RecordingsRepository();
const recordingsStore = getModule(RecordingsStore);
const blockNumbersRepository = new BlockedPhoneNumbersRepository();
const textsStore = getModule(TextsStore);
const layoutTabsStore = getModule(LayoutTabsStore, store);
const familiesRepository = new FamiliesRepository();

interface DisplayItem {
    item_type: ActionItemType;
    item_id: number;
    guardian: string;
    type_icon: string;
    type: string;
    description: string | null;
    assignee: string;
    avatar: AvatarValues | null;
    location: string;
    is_duplicate: boolean;
    time: string;
    pending_family: PendingFamily | null;
    task_group_id: number | null;
    action: Array<string>;
}

type GroupTypeOption = ActionItemGroupType | 'all';

interface GroupType {
    label: string;
    type: GroupTypeOption;
}

@Component({
    components: {
        DuplicatesReviewModal,
        BaseClose,
        CenterlessFamilySelection,
        PotentialDuplicate,
        RejectFamily,
        PendingFamilyHubModal,
        RecordingAudioModal,
        AddFamily,
        ViewMessageModal,
        AddTaskModal,
        TourCalendarModal,
        SendMessageModal,
        ManageTaskModal
    }
})
export default class LocationDashboardToDo extends Mixins(LocaleMixin, CrmBreakpointsMixin) {
    @Prop() isCenter!: boolean;
    @Prop() includeMeetings!: boolean;
    @Prop({ default: false }) showAddTasksButton!: boolean;
    @Prop({ default: false }) showAddMessagesButton!: boolean;
    @Prop() items!: Array<ActionItem>;
    @Prop() itemsCount!: number;
    @Prop() customNoResults!: string | null;
    @Prop() noDataText!: string | null;
    @Prop({ default: false }) readonly etDashMode!: boolean;
    @PropSync('tableOptions') tableOptionsSync!: DataTableOptions;

    private loadingKey = LocationDashboard.name;

    private actionCompare = PendingFamilyActions.COMPARE;
    private columnSize = 6;
    private dialogSize = 'dialog-medium';
    private displayItems: Array<DisplayItem> = [];
    private duplicateFamilies: Array<FamilyLink> | null = null;
    private duplicateReasonId: number | null = null;
    private eventClose = EventTypes.CLOSE;
    private eventFamilyAccepted = EventTypes.FAMILY_ACCEPTED
    private eventFamilyRejected = EventTypes.FAMILY_REJECTED;
    private eventNumberBlocked = EventTypes.PHONE_NUMBER_BLOCKED;

    private invalidReasonId: number | null = null;
    private rejectedReasonId: number | null = null;
    private selectedFamily: PendingFamily | null = null;
    private selectedRecordings: Array<PendingFamilyRecording | Recording> = [];
    private showAddFamily = false;
    private showCenterlessFamilySelection = false;
    private showDuplicateDialog = false;
    private showRejectDialog = false;
    private typesFamilySources: Array<CrmTypeOption> = [];
    private typesInquiry: Array<CrmTypeOption> = [];
    private viewPendingFamily = false;
    private familyAccepted = false;

    private isMeeting = false;
    private showAddTask = false;
    private isSendMessage = false;
    private showCalendar = false;
    private startDateTime: string | null = null;
    private duration = 0;
    private selectedMeeting: Task | null = null;
    private isMeetingSelected = false;

    private currentGroupType: GroupTypeOption = 'all';
    private closeEvent = EventTypes.CLOSE;
    private sentEvent = EventTypes.MESSAGE_SENT;
    private updatedEvent = EventTypes.UPDATED;
    private openEditTaskModalEvent = EventTypes.MEETING_EDIT;
    private openTaskModalEvent = EventTypes.MEETING_ADD;

    private filteredCounts = new ToDoCounts();
    private totalCounts = new ToDoCounts();

    private addTaskModal = false;

    private addAnotherTaskEvent = EventTypes.TASK_ADD_ANOTHER;
    private createdEvent = EventTypes.CREATED;
    private managedTask: Task | null = null;
    private taskFamily: Family | null = null;

    private showMessageModal = false;
    private messageFamilyId = 0;
    private messageIsFb = false;
    private emailId = 0;

    private showRecordingModal = false;
    private readTextFromPotentiallyAcceptedLead = true;
    private pstToursAvailabilityTimeRanges: Array<PstToursAvailabilityTimeRange> = [];

    private potentialDuplicates: Array<Family | PendingFamily> = [];

    private get headers(): Array<DataTableHeader> {
        const headers: Array<DataTableHeader> = [
            {
                text: 'Guardian Name',
                value: 'guardian',
                width: this.guardianWidth
            },
            {
                text: 'Type',
                value: 'type',
                width: this.typeWidth
            },
            {
                text: 'Assignee',
                value: 'assignee',
                width: this.assigneeWidth
            }
        ];
        if (!this.isCenter) {
            headers.push({
                text: 'Location',
                value: 'location',
                width: this.locationWidth
            });
        }
        headers.push(
            {
                text: 'Time',
                value: 'datetime',
                class: 'time',
                width: this.timeWidth
            },
            {
                text: '',
                width: '28px',
                value: 'is_duplicate',
                sortable: false
            },
            {
                text: '',
                value: 'action',
                width: this.actionWidth,
                class: 'action',
                sortable: false
            }
        );
        return headers;
    }

    get actionWidth() {
        switch (this.crmBreakpoint) {
            case 'sm':
                return '13ch';
            case 'md':
                return '14ch';
            case 'lg':
                return '16ch';
            case 'xl':
                return '18ch';
        }
    }

    get assigneeWidth() {
        switch (this.crmBreakpoint) {
            case 'sm':
                return this.isMini || this.isCenter ? '20ch' : '12ch';
            case 'md':
                return this.isMini || this.isCenter ? '28ch' : '20ch';
            case 'lg':
                return this.isMini || this.isCenter ? '32ch' : '24ch';
            case 'xl':
                return '36ch';
        }
    }

    get avatarSize() {
        switch (this.crmBreakpoint) {
            case 'sm':
                return '30';
            case 'md':
                return '32';
            case 'lg':
                return '34';
            case 'xl':
                return '36';
        }
    }

    get canAddCrmTabs(): boolean {
        return featuresState.isFeatureEnabled(FeatureConstants.INCONTACT) && authState.isEnrollmentTeamMember;
    }

    get guardianWidth() {
        switch (this.crmBreakpoint) {
            case 'sm':
                return '16ch';
            case 'md':
                return '18ch';
            case 'lg':
                return '20ch';
            case 'xl':
                return '22ch';
        }
    }

    get isCrmPlusMode(): boolean {
        return featuresState.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get isMini() {
        return appState.isMini;
    }

    get locationWidth() {
        switch (this.crmBreakpoint) {
            case 'sm':
                return this.isMini ? '25ch' : '18ch';
            case 'md':
                return this.isMini ? '28ch' : '20ch';
            case 'lg':
                return this.isMini ? '36ch' : '28ch';
            case 'xl':
                return '40ch';
        }
    }

    get isTextFromPendingLead() {
        return this.selectedFamily && this.selectedFamily.texts.length > 0;
    }

    get newTabStatus() {
        return this.etDashMode && appState.familyHubTargetMode;
    }

    get timeWidth() {
        switch (this.crmBreakpoint) {
            case 'sm':
                return '21ch';
            case 'md':
                return '22ch';
            case 'lg':
                return '24ch';
            case 'xl':
                return '27ch';
        }
    }

    get timezone() {
        return authState.userTimeZone;
    }

    get typeWidth() {
        switch (this.crmBreakpoint) {
            case 'sm':
                return this.isMini || this.isCenter ? '22ch' : '18ch';
            case 'md':
                return this.isMini || this.isCenter ? '28ch' : '24ch';
            case 'lg':
                return this.isMini || this.isCenter ? '34ch' : '30ch';
            case 'xl':
                return '44ch';
        }
    }

    async getIcon(item: ActionItem) {
        return await actionItemUtils.getIcon(item);
    }

    @Watch('items', { immediate: true })
    async itemsChanged() {
        await this.updateDisplayItems();
    }

    async created() {
        await this.init();
    }

    async init() {
        loadingState.loadingIncrement(this.loadingKey);
        await Promise.all([
            typeStore.initList(CrmTypeList.FAMILY_INQUIRY),
            typeStore.initList(CrmTypeList.FAMILY_SOURCE),
            typeStore.initList(CrmTypeList.REJECTED_REASONS)
        ]);
        this.typesInquiry = typeStore.listOptions(CrmTypeList.FAMILY_INQUIRY);
        this.typesFamilySources = typeStore.listOptions(CrmTypeList.FAMILY_SOURCE);
        const reasons = typeStore.listOptions(CrmTypeList.REJECTED_REASONS);
        this.duplicateReasonId = reasons.find(reason => reason.identifier === RejectedReasonIdentifiers.DUPLICATE)?.id ?? null;
        this.invalidReasonId = reasons.find(reason => reason.identifier === RejectedReasonIdentifiers.INVALID)?.id ?? null;
        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * Handle trying to add a meeting from the calendar action.
     *
     * @param payload
     */
    private handleAddMeeting(payload: AddMeetingEventPayload) {
        this.startDateTime = payload.startDateTime;
        this.duration = payload.duration;
        this.showAddTask = true;
        this.pstToursAvailabilityTimeRanges = payload.pstToursAvailabilityTimeRanges;
    }

    /**
     * Handle trying to edit a meeting from the calendar action.
     *
     * @param payload
     */
    private handleEditMeeting(payload: EditMeetingEventPayload) {
        this.selectedMeeting = payload.selectedTask;
        this.isMeetingSelected = true;
    }

    loadData() {
        this.$emit(EventTypes.UPDATED);
    }

    async updateDisplayItems() {
        this.displayItems = [];
        for (const item of this.items) {
            const icon = await actionItemUtils.getIcon(item);
            this.displayItems.push({
                item_id: item.item_id,
                item_type: item.item_type,
                guardian: item.guardian_first_name + ' ' + item.guardian_last_name,
                description: actionItemUtils.getDescription(item),
                location: item.location_name,
                assignee: item.assignee_first_name + ' ' + item.assignee_last_name,
                avatar: actionItemUtils.getAvatar(item),
                time: this.formatShortDateTimeWithTimezone(item.date_time, this.timezone),
                type: item.display_type,
                type_icon: icon,
                is_duplicate: !!item.pending_family && item.pending_family.is_duplicate,
                pending_family: item.pending_family,
                action: this.getActionItems(item),
                task_group_id: item.task_group_id
            });
        }
    }

    private async acceptFamily(acceptFamilyEventPayload?: AcceptFamilyEventPayload): Promise<void> {
        if (!this.selectedFamily) {
            return;
        }

        // Force user to select a center to accept family if the pending family lacks a center
        this.showCenterlessFamilySelection = !this.selectedFamily.center && !acceptFamilyEventPayload?.centerId && this.isCrmPlusMode;

        if (this.showCenterlessFamilySelection) {
            return;
        }

        loadingState.loadingIncrement(this.loadingKey);

        try {
            if (!acceptFamilyEventPayload?.acceptedInDuplicateModal) {
                await pendingFamilyService.acceptFamily(this.selectedFamily.id, acceptFamilyEventPayload);
            }

            await this.acceptedSideEffects();
        } catch (e) {
            await this.$swal({
                text: 'Something went wrong, and the family could not be accepted. Please try again later.',
                icon: 'error'
            });
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * Perform the side effects after accepting the family.
     */
    private async acceptedSideEffects() {
        loadingState.loadingIncrement(this.loadingKey);
        this.familyAccepted = true;
        if (this.isTextFromPendingLead) {
            if (!this.readTextFromPotentiallyAcceptedLead) {
                // There isn't an edge right now to decrement inbox count from accepted families because
                // before accepting, texts from pending lead does not account in the inbox count, so if accepted
                // family and message is already do nothing, but if message is mark unread, we have to increment to inbox count
                textsStore.incrementInboxCount();
            }
            if (this.showMessageModal) {
                this.showMessageModal = false;
            }
        }

        // Default back to true to avoid messing up other count
        this.readTextFromPotentiallyAcceptedLead = true;
        await this.loadData();
        loadingState.loadingDecrement(this.loadingKey);
    }

    private async blockNumber() {
        loadingState.loadingIncrement(this.loadingKey);
        try {
            if (this.selectedFamily && this.selectedFamily.primary_guardian.primary_phone) {
                await blockNumbersRepository.blockPhoneNumber({ number: this.selectedFamily.primary_guardian.primary_phone.number_e164 });
                await this.loadData();
            }
        } catch (e) {
            await this.$swal({ text: 'Something went wrong when blocking this number', icon: 'error' });
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    closeRejectDialog() {
        this.showRejectDialog = false;
    }

    closeDuplicateDialog() {
        this.showDuplicateDialog = false;
    }

    /**
     * Get the list of actions items to show when clicking on the action button.
     *
     * @param item
     */
    getActionItems(item: ActionItem): Array<string> {
        switch (item.item_type) {
            case ActionItemType.PENDING:
                return getPendingFamilyActions(item.pending_family as PendingFamily);
            case ActionItemType.CALL:
                // only other type that actually returns anything here, to be implemented in 12537
                return [RecordingActions.PLAY, RecordingActions.MARK_AS_LISTENED, RecordingActions.VIEW_FAMILY];
            default:
                // all other types are handled by direct action, in handleActionButtonClick()
                return [];
        }
    }

    private handleAction(item: DisplayItem, actionString: string) {
        switch (item.item_type) {
            case ActionItemType.PENDING:
                this.handlePendingActions(item.pending_family as PendingFamily, actionString as PendingFamilyActions);
                break;
            case ActionItemType.CALL:
                this.handleRecordingActionClick(item, actionString as RecordingActions);
                break;
            default:
                break;
        }
    }

    private async handlePendingActions(pendingFamily: PendingFamily, actionType: PendingFamilyActions): Promise<void> {
        this.selectedFamily = pendingFamily;
        this.rejectedReasonId = (pendingFamily.is_duplicate && (pendingFamily.duplicates?.length ?? 0) > 0) ? this.duplicateReasonId : this.invalidReasonId;

        switch (actionType) {
            case PendingFamilyActions.VIEW:
                this.viewPendingFamily = true;
                break;
            case PendingFamilyActions.COMPARE:
                loadingState.loadingIncrement(this.loadingKey);
                if (!this.selectedFamily) {
                    break;
                }

                this.duplicateFamilies = this.selectedFamily.duplicates;

                if (!this.duplicateFamilies) {
                    break;
                }
                this.potentialDuplicates = [];
                this.potentialDuplicates.push(this.selectedFamily);

                for (const duplicate of this.duplicateFamilies) {
                    const family = await familiesRepository.getOne(duplicate.id);
                    this.potentialDuplicates.push(family);
                }

                loadingState.loadingDecrement(this.loadingKey);
                this.showDuplicateDialog = true;
                break;
            case PendingFamilyActions.ACCEPT:
                await this.acceptFamily();
                break;
            case PendingFamilyActions.REJECT:
                this.showRejectDialog = true;
                break;
            case PendingFamilyActions.PLAY_RECORDING:
                this.selectedRecordings = this.selectedFamily.call_recordings;
                this.showRecordingModal = true;
                break;
            case PendingFamilyActions.NEW_FAMILY:
                this.showAddFamily = true;
                break;
        }
    }

    // reject the family
    private async rejectFamily(preferredFamilyId: number | null = null, reasonId: number | null = null, rejectedInDuplicateModal = false): Promise<void> {
        if (!this.selectedFamily?.id) {
            return;
        }

        this.showRejectDialog = false;
        reasonId = reasonId ?? this.rejectedReasonId;

        loadingState.loadingIncrement(this.loadingKey);

        try {
            if (!rejectedInDuplicateModal) {
                await pendingLeadsRepository.rejectFamily(this.selectedFamily.id as number, preferredFamilyId, reasonId);
            }
            await this.loadData();
        } catch (e) {
            this.showRejectDialog = true;
            await this.$swal({ text: 'Something went wrong and the family could not be rejected. Please try again later.', icon: 'error' });
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * This is for when there are no menu items for the item action options.
     */
    private async handleActionButtonClick(item: DisplayItem) {
        this.selectedFamily = null;
        switch (item.item_type) {
            case ActionItemType.TASK:
                loadingState.loadingIncrement(this.loadingKey);
                this.isMeeting = [TaskGroups.MEETINGS, TaskGroups.TOURS].includes(item.task_group_id as number);
                this.managedTask = await tasksRepo.getOne(item.item_id);
                loadingState.loadingDecrement(this.loadingKey);
                break;
            case ActionItemType.EMAIL:
                // open email modal
                this.messageIsFb = false;
                this.messageFamilyId = 0;
                this.emailId = item.item_id;
                this.showMessageModal = true;
                break;
            case ActionItemType.TEXT:
                this.messageIsFb = false;
                this.messageFamilyId = item.item_id;
                this.emailId = 0;
                this.showMessageModal = true;
                break;
            case ActionItemType.FACEBOOK:
                this.messageIsFb = true;
                this.messageFamilyId = item.item_id;
                this.emailId = 0;
                this.showMessageModal = true;
                break;
            case ActionItemType.PENDING:
                if (item.pending_family) {
                    this.selectedFamily = item.pending_family;
                    this.rejectedReasonId = (this.selectedFamily.is_duplicate && (this.selectedFamily.duplicates?.length ?? 0) > 0) ? this.duplicateReasonId : this.invalidReasonId;
                    if (item.pending_family.texts.length > 0) {
                        this.messageFamilyId = item.item_id;
                        this.showMessageModal = true;
                    }
                }
        }
    }

    private async handleRecordingActionClick(item: DisplayItem, action: RecordingActions) {
        loadingState.loadingIncrement(this.loadingKey);
        const recording = await recordingsRepo.getOne(item.item_id);
        switch (action) {
            case RecordingActions.PLAY:
                this.selectedRecordings = [recording];
                this.showRecordingModal = true;
                break;
            case RecordingActions.MARK_AS_LISTENED:
                await recordingsRepo.markAsRead(recording, true, this.etDashMode);
                await this.loadData();
                recordingsStore.decrementInboxCount();
                break;
            case RecordingActions.VIEW_FAMILY:
                if (recording.family) {
                    await this.navigateToFamilyHub(recording.family);
                }
                break;
        }
        loadingState.loadingDecrement(this.loadingKey);

    }

    /**
     * helpers for task modal
     */

    openAddAnotherTaskModal(family: Family) {
        this.taskFamily = family;
        this.addTaskModal = true;
    }

    async retrieveUpdateFromMessageModal(isRead: boolean) {
        if (this.isTextFromPendingLead && !isRead) {
            this.readTextFromPotentiallyAcceptedLead = false;
        }
        await this.loadData();
    }

    resetTask() {
        this.managedTask = null;
        this.taskFamily = null;
    }

    private async navigateToFamilyHub(familyLink: FamilyLink) {
        if (this.newTabStatus) {
            if (this.canAddCrmTabs) {
                await layoutTabsStore.addTab({
                    routeName: 'family-hub',
                    routeParams: { id: familyLink.id.toString() },
                    goTo: true,
                    tabTitle: familyLink.values.name
                });
            } else {
                const routeData = this.$router.resolve({
                    name: 'family-hub',
                    params: { id: familyLink.id.toString() }
                });
                window.open(routeData.href, '_blank');
            }
        } else {
            await this.$router.push({
                name: 'family-hub',
                params: { id: familyLink.id.toString() }
            });
        }
    }

}
