
































































import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { AnonInfoService } from '@/customers/anon-info-service';

const loadingState = getModule(LoadingStore);
const infoService = new AnonInfoService();
@Component
export default class TermsConditions extends Vue {
    private loadingKey = 'termsConditions';
    private legalName = '';
    private locationPhone = '';
    private locationEmail = '';

    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        const info = await infoService.getAnonInfo(String(this.$route.query.formId), String(this.$route.query.cid));
        this.legalName = info.legal_name || 'Your Service Provider';
        this.locationPhone = info.location_phone || "the Service Provider's phone number";
        this.locationEmail = info.location_email || "the Service Provider's email address";
        loadingState.loadingDecrement(this.loadingKey);
    }

}
