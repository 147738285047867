



















import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class DuplicatesDifferenceIndicator extends Vue {
    @Prop({ type: Boolean, default: true }) readonly show!: boolean;
    @Prop({ type: String, default: 'Note that different families have different data in this row. Click to edit data if desired.' }) readonly tooltipText!: string;
}
