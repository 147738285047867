import { render, staticRenderFns } from "./DuplicatesReviewModal.vue?vue&type=template&id=dd81eaa4&scoped=true&"
import script from "./DuplicatesReviewModal.vue?vue&type=script&lang=ts&"
export * from "./DuplicatesReviewModal.vue?vue&type=script&lang=ts&"
import style0 from "./DuplicatesReviewModal.vue?vue&type=style&index=0&id=dd81eaa4&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd81eaa4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCardActions,VCardText,VCardTitle,VIcon,VRow,VSpacer,VTooltip})
