import {
    Family,
    FamilyComparison, FamilyFieldPaths,
    FamilyUpdateDtoInterface, PotentialDuplicateActionConstants,
    PotentialDuplicateLeadActions
} from '@/families/models/family';
import { BaseStatuses } from '@/constants/status-constants';
import {
    FamilyEntry,
    ChildEntry,
    PotentialDuplicateActionOption
} from '@/families/services/potential-duplicate-service';
import {
    ChildCreateDtoInterface,
    ChildFieldPaths,
    ChildPostDTO,
    ChildUpdateDtoInterface
} from '@/families/models/child';
import cloneDeep from 'lodash/cloneDeep';
import { ChangeStatus } from '@/families/change-status';

export function disableSave(familiesActions: Array<PotentialDuplicateLeadActions | undefined>, currentFamily: FamilyUpdateDtoInterface, existingFamilies: Array<FamilyComparison>): boolean {
    let countReject = 0;
    let countUndefined = 0;
    let isSaveDisabled: boolean;

    const linkToRejectedCurrentFamily = familiesActions[0] === PotentialDuplicateLeadActions.REJECT && familiesActions.slice(1).filter(action => action === PotentialDuplicateLeadActions.LINK).length > 0;
    let countCurrentFamilyLinkToRejectedExisting = 0;

    familiesActions.forEach((action, index) => {
        if (action === undefined) {
            countUndefined++;
        }
        if (action === PotentialDuplicateLeadActions.REJECT) {
            countReject++;
        }
        // this is to make sure that save is disabled if you end up with a link-reject-any option, and reject is from the different center
        if (familiesActions[0] === PotentialDuplicateLeadActions.LINK && action === PotentialDuplicateLeadActions.REJECT && index !== 0 && existingFamilies[index - 1].current.primary_guardian.center_id !== currentFamily.primary_guardian.center_id) {
            countCurrentFamilyLinkToRejectedExisting++;
        }
    });

    if (familiesActions.length > 2) {
        const bothExistingFamiliesAtDiffCenter = existingFamilies.filter(family => family.current.primary_guardian.center_id !== currentFamily.primary_guardian.center_id).length === 2;
        const currentFamilyLinkToRejectedExisting = bothExistingFamiliesAtDiffCenter ? countCurrentFamilyLinkToRejectedExisting === 2 : countCurrentFamilyLinkToRejectedExisting === 1;
        isSaveDisabled = (countUndefined > 0 ||
                        countReject === 3 ||
                        linkToRejectedCurrentFamily ||
                        currentFamilyLinkToRejectedExisting);
    } else {
        isSaveDisabled = (countUndefined > 0 ||
                        countReject === 2 ||
                        linkToRejectedCurrentFamily ||
                        countCurrentFamilyLinkToRejectedExisting === 1);
    }

    return isSaveDisabled;
}

/**
 * Generate actions for current family
 * @param currentFamily
 * @param existingFamilies
 */
export function generationActionsForCurrentFamily(currentFamily: FamilyUpdateDtoInterface, existingFamilies: Array<FamilyComparison>): Array<{ value: PotentialDuplicateLeadActions; label: string }> {
    const actionOptions = [
        {
            value: PotentialDuplicateLeadActions.KEEP,
            label: 'Keep this record'
        },
        {
            value: PotentialDuplicateLeadActions.REJECT,
            label: 'Reject this record'
        }
    ];

    // AC wants different labels for linking families 😭

    // Handle 2 duplicate families case (current - existing - existing)
    if (existingFamilies.length > 1) {
        const existingFamiliesInDiffLoc = existingFamilies.filter(existingFamily => existingFamily.current.primary_guardian.center_id !== currentFamily.primary_guardian.center_id).length;

        // Handle action for when two duplicate families have different centers with the current one (center1 - center2 - center3)
        if (existingFamiliesInDiffLoc > 1) {
            actionOptions.push({
                value: PotentialDuplicateLeadActions.LINK,
                label: 'Link to the Existing Family/Families'
            });
            // Handle action for when only duplicate families have different centers with the current one (center1 - center2 - center1)
        } else if (existingFamiliesInDiffLoc === 1) {
            actionOptions.push({
                value: PotentialDuplicateLeadActions.LINK,
                label: 'Link to the Different Location Family'
            });
        }
    } else {
        // Handle 1 duplicate family (current - existing)
        for (const existingFamily of existingFamilies) {
            if (currentFamily.primary_guardian.center_id !== existingFamily.current.primary_guardian.center_id) {
                actionOptions.push({
                    value: PotentialDuplicateLeadActions.LINK,
                    label: 'Link to the Existing Family'
                });
                break;
            }
        }
    }

    return actionOptions;
}

/**
 * Generate actions for potential duplicate family
 * @param existingFamily
 * @param currentFamily
 */
export function generateActionsForPotentialDuplicate(existingFamily: FamilyUpdateDtoInterface, currentFamily: FamilyUpdateDtoInterface): Array<{ value: PotentialDuplicateLeadActions; label: string }> {
    const actionOptions = [
        {
            value: PotentialDuplicateLeadActions.KEEP,
            label: 'Keep this record'
        },
        {
            value: PotentialDuplicateLeadActions.REJECT,
            label: 'Reject this record'
        }
    ];

    // If potential duplicate families is at different center than the current one, offer this linking option
    if (existingFamily.primary_guardian.center_id !== currentFamily.primary_guardian.center_id) {
        actionOptions.push({
            value: PotentialDuplicateLeadActions.LINK,
            label: 'Link to the Family Being Viewed'
        });
    }

    return actionOptions;
}

/**
 * Filter duplicates, removing linked families and rejected ones, and ensure currentFamily only appears once and at the top
 * @param currentFamily
 * @param duplicates
 */
export function filterDuplicates(currentFamily: Family, duplicates: Array<Family>): Array<Family> {
    const excludedFamilyIds = currentFamily.linked_families?.length
        ? new Set(currentFamily.linked_families.map(linkedFamily => linkedFamily.family.id))
        : new Set();

    // Filter duplicates, removing linked families and rejected ones, and ensure currentFamily only appears once
    const filteredDuplicates = duplicates.filter(duplicate =>
        duplicate.id !== currentFamily.id &&
        !excludedFamilyIds.has(duplicate.id) &&
        duplicate.status?.id !== BaseStatuses.REJECTED
    );

    const currentCenterId = currentFamily.center?.id || null;

    // Separate duplicates by same center and other centers, then sort each group by id
    if (currentCenterId) {
        const sameCenterDuplicates = filteredDuplicates
            .filter(duplicate => duplicate.center && duplicate.center.id === currentCenterId)
            .sort((a, b) => a.id - b.id);

        const otherCenterDuplicates = filteredDuplicates
            .filter(duplicate => duplicate.center && duplicate.center.id !== currentCenterId)
            .sort((a, b) => a.id - b.id);
        return [currentFamily, ...sameCenterDuplicates, ...otherCenterDuplicates];
    }

    // Return currentFamily at the start, followed by the filtered duplicates
    return [currentFamily, ...filteredDuplicates];
}

/**
 * Get the dialog size based on the number of potential duplicates
 * @param numberOfFamilies
 */
export function getDialogSize(numberOfFamilies: number): string {
    switch (true) {
        case numberOfFamilies > 3:
            return 'potential-duplicate-dialog-x-large';
        case numberOfFamilies === 3:
            return 'potential-duplicate-dialog-large';
        default:
            return 'potential-duplicate-dialog-medium';
    }
}

/**
 * Groups children by full name across all families, ensuring each key in the map has an array
 * with entries for each family (placeholders added for families without that child).
 *
 * @param familiesDto - Array of FamilyUpdateDtoInterface
 * @param families - Array of Family
 * @returns Map where each key is a child's full name and the value is an array of MappedChild entries.
 */
export function groupChildrenByFullName(familiesDto: Array<FamilyUpdateDtoInterface>, families: Array<Family>): Map<string, ChildEntry[]> {
    const changeStatusUtil = new ChangeStatus();
    const childrenMap = new Map<string, ChildEntry[]>();

    const numberOfFamilies = familiesDto.length;

    // Step 1: Populate the map with child entries from each family
    familiesDto.forEach((familyDto, familyIndex) => {
        familyDto.children.forEach((childDto, childIndex) => {
            const fullName = `${childDto.first_name} ${childDto.last_name}`; // Create full name key

            if (!childrenMap.has(fullName)) {
                // Initialize with placeholders to ensure the array length matches the number of families
                childrenMap.set(fullName, Array(numberOfFamilies).fill(null));
            }

            // Place the actual child at the correct family index
            childrenMap.get(fullName)![familyIndex] = {
                childDto: childDto,
                child: families[familyIndex].children[childIndex],
                family: families[familyIndex],
                availableStatuses: [],
                statusUpdates: changeStatusUtil.setStatusChangeDetails(childDto.status || 1, families[familyIndex], families[familyIndex].children[childIndex])
            };
        });
    });

    // Step 2: Fill in missing children with placeholders
    childrenMap.forEach((childrenList) => {
        for (let i = 0; i < numberOfFamilies; i++) {
            if (!childrenList[i]) {
                // Create a placeholder child if the family does not have this child
                const childDto: ChildCreateDtoInterface | ChildUpdateDtoInterface = new ChildPostDTO();
                childDto.id = -1 * (families[i].id + families[i].children.length + i);
                childDto.status = null;

                // Add the placeholder to the list at the correct family index
                childrenList[i] = {
                    childDto: childDto,
                    child: null,
                    family: families[i],
                    availableStatuses: [], // Will calculate this later,
                    statusUpdates: null
                };
            }
        }

        // Step 3: Collect unique statuses across all families for the same child name
        const uniqueStatuses = new Set<number>();

        childrenList.forEach((entry) => {
            if (entry.childDto.status) {
                uniqueStatuses.add(entry.childDto.status); // Collect status_id from childDto
            }
        });

        const statusesArray = Array.from(uniqueStatuses); // Convert Set to Array

        // Step 4: Assign availableStatuses to each child (including placeholders)
        childrenList.forEach((entry) => {
            entry.availableStatuses = statusesArray;
        });
    });

    return childrenMap;
}

export function getNestedProperty(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}

/**
 * Utility function to check if there are differences for a specific field across families.
 * @param families - Array of FamilyUpdateDtoInterface
 * @param field - The field name to compare
 * @returns boolean - true if there are differences, false otherwise
 */
export function hasFamilyDifferences(families: Array<FamilyUpdateDtoInterface>, field: string): boolean {
    const uniqueValues = new Set(
        families.map(family => getNestedProperty(family, field))
    );
    // Remove empty or null or undefined values before checking uniqueness
    uniqueValues.delete('');
    uniqueValues.delete(null);
    uniqueValues.delete(undefined);
    return uniqueValues.size > 1;
}

/**
 * Utility function to generate a map of fields with their respective differences across families.
 * @param families - Array of FamilyUpdateDtoInterface
 * @returns object - An object with keys as field names and values as booleans indicating differences
 */
export function generateDifferencesFamilyMap(families: Array<FamilyUpdateDtoInterface>): Record<string, boolean> {
    return {
        name: hasFamilyDifferences(families, FamilyFieldPaths.FIRST_NAME) || hasFamilyDifferences(families, FamilyFieldPaths.LAST_NAME),
        address1: hasFamilyDifferences(families, FamilyFieldPaths.ADDRESS1),
        address2: hasFamilyDifferences(families, FamilyFieldPaths.LOCALITY) ||
                 hasFamilyDifferences(families, FamilyFieldPaths.REGION) ||
                 hasFamilyDifferences(families, FamilyFieldPaths.POSTAL_CODE),
        phone: hasFamilyDifferences(families, FamilyFieldPaths.PHONE_NUMBER),
        email: hasFamilyDifferences(families, FamilyFieldPaths.EMAIL),
        inquiry_type: hasFamilyDifferences(families, FamilyFieldPaths.INQUIRY_TYPE),
        source_type: hasFamilyDifferences(families, FamilyFieldPaths.SOURCE_TYPE),
        status: hasFamilyDifferences(families, FamilyFieldPaths.STATUS)
    };
}

/**
 * Utility function to check if there are differences for a specific field across grouped children.
 * @param children
 * @param field - The field name to compare
 * @returns boolean - true if there are differences, false otherwise
 */
export function hasChildDifferences(children: Array<ChildUpdateDtoInterface | ChildCreateDtoInterface>, field: string): boolean {
    const uniqueValues = new Set(children.map(child => getNestedProperty(child, field)));
    // Remove empty or null or undefined values before checking uniqueness
    uniqueValues.delete('');
    uniqueValues.delete(null);
    uniqueValues.delete(undefined);
    return uniqueValues.size > 1;
}

/**
 * Utility function to generate a map of fields with their respective differences across grouped children.
 * @param children - Array of GroupedChildEntry objects grouped by child name
 * @returns object - An object with keys as field names and values as booleans indicating differences
 */
export function generateDifferencesChildrenMap(children: Array<ChildUpdateDtoInterface | ChildCreateDtoInterface>): Record<string, boolean> {
    return {
        name: hasChildDifferences(children, ChildFieldPaths.FIRST_NAME) || hasChildDifferences(children, ChildFieldPaths.LAST_NAME),
        date_of_birth: hasChildDifferences(children, ChildFieldPaths.DATE_OF_BIRTH),
        status: hasChildDifferences(children, ChildFieldPaths.STATUS)
    };
}

/**
 * Utility function to generate action options for a specific family.
 * The options are derived from the provided families list, family map, and selected actions.
 *
 * @param familyId - The ID of the family to get action options for.
 * @param familyEntries
 * @param selectedActionOptions - A record of selected actions for each family.
 * @returns An array of PotentialDuplicateActionOption objects.
 */
export function getActionOptionsForFamily(
    familyId: number,
    familyEntries: Array<FamilyEntry>, // Replace `any` with the actual family type if available
    selectedActionOptions: Record<number, PotentialDuplicateActionOption | null>
): Array<PotentialDuplicateActionOption> {
    const options: PotentialDuplicateActionOption[] = [];

    // Always add "Save This Record"
    options.push({
        text: 'Save This Record',
        value: `${PotentialDuplicateActionConstants.SAVE}-${familyId}`
    });

    // Add "Merge to ID ___" for records at the same location marked as "Save This Record"
    familyEntries.forEach((familyEntry) => {
        if (
            familyEntry.familyDto.id !== familyId &&
            familyEntry.familyDto.primary_guardian.center_id === familyEntries.find(f => f.familyDto.id === familyId)?.familyDto.primary_guardian.center_id && // Same location
            selectedActionOptions[familyEntry.familyDto.id]?.value.split('-')[0] === PotentialDuplicateActionConstants.SAVE// Marked as "Save This Record"
        ) {
            options.push({
                text: `Merge to ID ${familyEntry.familyDto.id}`,
                value: `${PotentialDuplicateActionConstants.MERGE}-${familyEntry.familyDto.id}`
            });
        }
    });

    // Add "Link to ID ___" for records at different locations marked as "Save This Record"
    familyEntries.forEach((familyEntry) => {
        if (
            familyEntry.familyDto.id !== familyId &&
            familyEntry.familyDto.primary_guardian.center_id !== familyEntries.find(f => f.familyDto.id === familyId)?.familyDto.primary_guardian.center_id && // Different location
            selectedActionOptions[familyEntry.familyDto.id]?.value.split('-')[0] === PotentialDuplicateActionConstants.SAVE // Marked as "Save This Record"
        ) {
            options.push({
                text: `Link to ID ${familyEntry.familyDto.id}`,
                value: `${PotentialDuplicateActionConstants.LINK}-${familyEntry.familyDto.id}`
            });
        }
    });

    // Add "Skip/Hide This Record" (not for the Family Being Viewed)
    if (familyId !== familyEntries[0].familyDto.id) {
        options.push({
            text: 'Skip/Hide This Record',
            value: `${PotentialDuplicateActionConstants.HIDE}-${familyId}`
        });
    }

    // Add "Reject This Record"
    options.push({
        text: 'Reject This Record',
        value: `${PotentialDuplicateActionConstants.REJECT}-${familyId}`
    });

    return options;
}

/**
 * Merges the source family into the target family.
 * This function modifies the target family by combining its data with the source family.
 * The source family data remains unchanged.
 *
 * @returns The updated target family.
 * @param targetFamilyEntry
 * @param sourceFamilyEntry
 * @param familyEntries
 */
export function mergeGuardianInfo(targetFamilyEntry: FamilyEntry, sourceFamilyEntry: FamilyEntry, familyEntries: Array<FamilyEntry>): void {
    const targetFamilyDto = targetFamilyEntry.familyDto;
    const sourceFamilyDto = sourceFamilyEntry.familyDto;
    targetFamilyDto.primary_guardian.first_name = targetFamilyDto.primary_guardian.first_name || sourceFamilyDto.primary_guardian.first_name;
    targetFamilyDto.primary_guardian.last_name = targetFamilyDto.primary_guardian.last_name || sourceFamilyDto.primary_guardian.last_name;
    if (targetFamilyDto.primary_guardian.address && sourceFamilyDto.primary_guardian.address) {
        targetFamilyDto.primary_guardian.address.address1 = targetFamilyDto.primary_guardian.address.address1 || sourceFamilyDto.primary_guardian.address.address1;
        targetFamilyDto.primary_guardian.address.locality = targetFamilyDto.primary_guardian.address.locality || sourceFamilyDto.primary_guardian.address.locality;

        targetFamilyDto.primary_guardian.address.region = targetFamilyDto.primary_guardian.address.region || sourceFamilyDto.primary_guardian.address.region;

        targetFamilyDto.primary_guardian.address.postcode = targetFamilyDto.primary_guardian.address.postcode || sourceFamilyDto.primary_guardian.address.postcode;
    }

    if (targetFamilyDto.primary_guardian.primary_phone && sourceFamilyDto.primary_guardian.primary_phone) {
        targetFamilyDto.primary_guardian.primary_phone.number_e164 = targetFamilyDto.primary_guardian.primary_phone.number_e164 || sourceFamilyDto.primary_guardian.primary_phone.number_e164;
    }

    targetFamilyDto.primary_guardian.email = targetFamilyDto.primary_guardian.email || sourceFamilyDto.primary_guardian.email;
    targetFamilyDto.inquiry_type = targetFamilyDto.inquiry_type || sourceFamilyDto.inquiry_type;
    targetFamilyDto.source_type = targetFamilyDto.source_type || sourceFamilyDto.source_type;

    const targetFamily = targetFamilyEntry.family;
    const sourceFamily = sourceFamilyEntry.family;
    const targetStatusUpdates = targetFamilyEntry.statusUpdates;
    const sourceStatusUpdates = sourceFamilyEntry.statusUpdates;

    if (targetFamily.children.length === 0 && sourceFamily.children.length === 0 && targetStatusUpdates && sourceStatusUpdates) {
        if (targetStatusUpdates.status === sourceStatusUpdates.status) {
            targetStatusUpdates.reason = targetStatusUpdates.reason || sourceStatusUpdates.reason;
            targetStatusUpdates.comments = targetStatusUpdates.comments || sourceStatusUpdates.comments;
        }
    }
    const updatedFamilyEntry = { familyDto: targetFamilyDto, family: targetFamily, statusUpdates: targetStatusUpdates };
    const updatedFamilyIndex = familyEntries.findIndex(entry => entry.familyDto.id === targetFamilyDto.id);
    familyEntries[updatedFamilyIndex] = updatedFamilyEntry;
}

/**
 * Merges the source family into the target family.
 * This function modifies the target family by combining its data with the source family.
 * The source family data remains unchanged.
 *
 * @returns The updated target family.
 * @param targetFamilyEntry
 * @param sourceFamilyEntry
 * @param childrenGroupedByName
 */
export function mergeChildInfo(targetFamilyEntry: FamilyEntry, sourceFamilyEntry: FamilyEntry, childrenGroupedByName: Record<string, ChildEntry[]>): void {
    Object.entries(childrenGroupedByName).forEach(([fullName, childrenList]) => {
        const targetIndex = childrenList.findIndex(entry => entry.family.id === targetFamilyEntry.family.id);
        const sourceIndex = childrenList.findIndex(entry => entry.family.id === sourceFamilyEntry.family.id);

        if (targetIndex !== -1 && sourceIndex !== -1) {
            const targetChildEntry = childrenList[targetIndex];
            const sourceChildEntry = childrenList[sourceIndex];

            const targetChildDto = targetChildEntry.childDto;
            const sourceChildDto = sourceChildEntry.childDto;

            // Merge basic child information
            targetChildDto.first_name = targetChildDto.first_name || sourceChildDto.first_name;
            targetChildDto.last_name = targetChildDto.last_name || sourceChildDto.last_name;
            targetChildDto.date_of_birth = targetChildDto.date_of_birth || sourceChildDto.date_of_birth;

            // Merge status updates
            const targetStatusUpdates = targetChildEntry.statusUpdates;
            const sourceStatusUpdates = sourceChildEntry.statusUpdates;

            if (targetStatusUpdates && sourceStatusUpdates && sourceStatusUpdates.status === targetStatusUpdates.status) {
                targetStatusUpdates.actual_start_date = targetStatusUpdates.actual_start_date || sourceStatusUpdates.actual_start_date;
                targetStatusUpdates.date = targetStatusUpdates.date || sourceStatusUpdates.date;
                targetStatusUpdates.expected_start_date = targetStatusUpdates.expected_start_date || sourceStatusUpdates.expected_start_date;
                targetStatusUpdates.reason = targetStatusUpdates.reason || sourceStatusUpdates.reason;
                targetStatusUpdates.comments = targetStatusUpdates.comments || sourceStatusUpdates.comments;

                // Merge wait list details
                if (targetStatusUpdates.wait_list_details && sourceStatusUpdates.wait_list_details) {
                    targetStatusUpdates.wait_list_details.is_child_of_staff =
                        targetStatusUpdates.wait_list_details.is_child_of_staff || sourceStatusUpdates.wait_list_details.is_child_of_staff;
                    targetStatusUpdates.wait_list_details.is_sibling_in_care =
                        targetStatusUpdates.wait_list_details.is_sibling_in_care || sourceStatusUpdates.wait_list_details.is_sibling_in_care;
                    targetStatusUpdates.wait_list_details.fee = targetStatusUpdates.wait_list_details.fee || sourceStatusUpdates.wait_list_details.fee;
                    targetStatusUpdates.wait_list_details.fee_paid_date =
                        targetStatusUpdates.wait_list_details.fee_paid_date || sourceStatusUpdates.wait_list_details.fee_paid_date;
                    targetStatusUpdates.wait_list_details.is_fee_paid =
                        targetStatusUpdates.wait_list_details.is_fee_paid || sourceStatusUpdates.wait_list_details.is_fee_paid;
                    targetStatusUpdates.wait_list_details.priority =
                        targetStatusUpdates.wait_list_details.priority || sourceStatusUpdates.wait_list_details.priority;
                    targetStatusUpdates.wait_list_details.type =
                        targetStatusUpdates.wait_list_details.type || sourceStatusUpdates.wait_list_details.type;
                }

                // Merge withdrawn details
                if (targetStatusUpdates.withdrawn_details && sourceStatusUpdates.withdrawn_details) {
                    targetStatusUpdates.withdrawn_details.good_standing =
                        targetStatusUpdates.withdrawn_details.good_standing || sourceStatusUpdates.withdrawn_details.good_standing;
                    targetStatusUpdates.withdrawn_details.is_eligible_for_reenrollment =
                        targetStatusUpdates.withdrawn_details.is_eligible_for_reenrollment || sourceStatusUpdates.withdrawn_details.is_eligible_for_reenrollment;
                }

                targetChildEntry.statusUpdates = targetStatusUpdates;
            } else if (!targetChildEntry.statusUpdates && sourceChildEntry.statusUpdates) {
                targetChildEntry.statusUpdates = cloneDeep(sourceChildEntry.statusUpdates);
                targetChildEntry.statusUpdates.family_id = targetFamilyEntry.familyDto.id;
            }

            // Update the target entry in childrenGroupedByName
            childrenGroupedByName[fullName][targetIndex] = targetChildEntry;
        }
    });
}

/**
* Filters out records with hide actions from the provided options.
*
* @param actionOptions - Record of action options keyed by ID
* @returns New record containing only non-hide actions
*/
export function filterNonHideActions(
    actionOptions: Record<number, PotentialDuplicateActionOption | null>
): Record<number, PotentialDuplicateActionOption | null> {
    return Object.fromEntries(
        Object.entries(actionOptions).filter(([, option]) => {
            if (!option) return false;
            const [actionType] = option.value.split('-');
            return actionType !== PotentialDuplicateActionConstants.HIDE;
        })
    );
}
