import { render, staticRenderFns } from "./DuplicatesDifferenceIndicator.vue?vue&type=template&id=d564a572&scoped=true&"
import script from "./DuplicatesDifferenceIndicator.vue?vue&type=script&lang=ts&"
export * from "./DuplicatesDifferenceIndicator.vue?vue&type=script&lang=ts&"
import style0 from "./DuplicatesDifferenceIndicator.vue?vue&type=style&index=0&id=d564a572&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d564a572",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VTooltip})
